"use client";

/**
 * Do not remove following two lazysizes imports. If this is removed most images will not load.
 * These imports are in the template file instead of the layout in order to ensure that they
 * are included in the client-side bundle.
 */
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";

export default function Template({ children }: { children: React.ReactNode }) {
  return children;
}
